import React, { useState, useContext } from 'react';
import Card from '@mui/material/Card';
import Box from 'components/Box';
import Typography from 'components/Typography';
import TimelineItem from 'components/TimelineItem';
import moment from 'moment/moment';
import Avatar from 'components/Avatar';
import { TextField } from '@mui/material';
import Button from 'components/Button';
import Loader from 'components/Loader';
import AuthContext from 'context/AuthContext';

function Notice({ value, handleAddComment, submitting, handleDelete }) {
  const [comment, setComment] = useState('');
  const authContext = useContext(AuthContext);

  return (
    <Card>
      <Box pt={3} px={3}>
        <Typography variant="h6" fontWeight="medium">
          {value.title}&nbsp;
          <Typography variant="button" color="text" fontWeight="regular">
            {moment(value.created_at).fromNow()} by {value.user.name}
          </Typography>
        </Typography>
        <Box mt={0} mb={2}>
          <Typography variant="button" color="text" fontWeight="regular">
            {value.content}
          </Typography>
        </Box>
      </Box>
      <Box p={2}>
        <Typography variant="button" color="text" fontWeight="regular">
          Comments
        </Typography>
        {value.comments.map((comment, index) => {
          return (
            <TimelineItem
              key={index}
              color="success"
              icon={<Avatar />}
              title={comment.user.name}
              dateTime={comment.created_at}
              description={comment.comment}
              lastItem={index === value.comments.length - 1}
              handleDelete={() => handleDelete(comment.id)}
              canDelete={authContext.user?.id === comment.user.id}
            />
          );
        })}
        <TextField
          value={comment}
          onChange={(event) => setComment(event.target.value)}
          placeholder="Type your comment..."
          fullWidth
          multiline
          rows={4}
        />
        <br />
        <br />
        <Button
          variant="outlined"
          color="info"
          disabled={submitting}
          onClick={async () => {
            const created = await handleAddComment(comment);
            if (created) setComment('');
          }}>
          {submitting ? <Loader /> : 'Post'}
        </Button>
      </Box>
    </Card>
  );
}

export default Notice;
