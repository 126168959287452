import React from 'react';
import './styles.css';

const Loader = () => {
  return (
    <div className="loader">
      <span className="dot dot-1"></span>
      <span className="dot dot-2"></span>
      <span className="dot dot-3"></span>
    </div>
  );
};

export default Loader;
