export const validateEmail = (email) => {
  let re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const validatePassword = (password) => {
  let re = /^(?=.*?[A-Za-z])(?=.*?[0-9]).{8,}$/;
  return re.test(password);
};
const formatter = new Intl.NumberFormat('en-ZM', {
  style: 'currency',
  currency: 'ZMW'
});

export const formatCurrency = (value) => {
  return formatter.format(value);
};
