import React from 'react';
import Dashboard from 'pages/dashboard';
import {
  Dashboard as DashboardIcon,
  ReceiptLong,
  Person,
  Email
} from '@mui/icons-material';
import * as ROUTES from 'constants/routes';
import Results from 'pages/results';
import Payments from 'pages/payments';
import Profile from 'pages/profile';

const routes = [
  {
    type: 'collapse',
    name: 'Dashboard',
    key: 'dashboard',
    icon: <DashboardIcon />,
    route: ROUTES.DASHBOARD,
    component: <Dashboard />
  },
  {
    type: 'collapse',
    name: 'Results',
    key: 'results',
    icon: <Email />,
    route: '/results',
    component: <Results />
  },
  {
    type: 'collapse',
    name: 'Invoices',
    key: 'invoices',
    icon: <ReceiptLong />,
    route: ROUTES.INVOICES,
    component: <Payments />
  },
  {
    type: 'collapse',
    name: 'Payments',
    key: 'payments',
    icon: <ReceiptLong />,
    route: ROUTES.PAYMENTS,
    component: <Payments />
  },
  {
    type: 'collapse',
    name: 'Profile',
    key: 'profile',
    icon: <Person />,
    route: '/profile',
    component: <Profile />
  }
];

export default routes;
