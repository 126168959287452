import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import Switch from '@mui/material/Switch';
import Box from 'components/Box';
import Typography from 'components/Typography';
import Input from 'components/Input';
import Button from 'components/Button';
import BasicLayout from 'pages/authentication/BasicLayout';
import bgImage from 'assets/images/bg-sign-in-basic.jpeg';
import { validateEmail } from 'utils/helpers';
import AuthContext from 'context/AuthContext';
import { DASHBOARD } from 'constants/routes';
import Snackbar from 'components/Snackbar';
import { Warning } from '@mui/icons-material';
import Loader from 'components/Loader';

function Login() {
  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [errors, setErrors] = useState({});
  const [nonFieldErrMsg, setNonFieldErrMsg] = useState('');
  const navigate = useNavigate();
  const context = useContext(AuthContext);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const onEmailChange = (event) => {
    let text = event.target.value;
    let newErrors = { ...errors };
    setEmail(text);
    if (text) {
      if (validateEmail(text)) {
        delete newErrors['email'];
      } else {
        newErrors['email'] = 'Please enter a valid email address';
      }
    } else {
      newErrors['email'] = 'Email address is required';
    }
    setErrors(newErrors);
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <Box
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center">
          <Typography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </Typography>
        </Box>
        <Box pt={4} pb={3} px={3}>
          <Box component="form" role="form">
            <Box mb={2}>
              <Input
                type="email"
                label="Email"
                fullWidth
                error={errors['email']?.length > 0}
                value={email}
                onChange={onEmailChange}
              />
            </Box>
            <Box mb={2}>
              <Input
                type="password"
                label="Password"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Box>
            <Box display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <Typography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: 'pointer', userSelect: 'none', ml: -1 }}>
                &nbsp;&nbsp;Remember me
              </Typography>
            </Box>
            <Box mt={4} mb={1}>
              <Button
                variant="gradient"
                color="info"
                fullWidth
                disabled={context.loading}
                onClick={async () => {
                  setNonFieldErrMsg('');
                  const body = await context
                    .login(email, password)
                    .catch((err) => {
                      setNonFieldErrMsg(err.message);
                    });
                  if (body) {
                    if (body.code === 200) {
                      navigate(DASHBOARD);
                    } else {
                      setNonFieldErrMsg(body.error);
                    }
                  }
                }}>
                {context.loading ? <Loader /> : 'sign in'}
              </Button>
            </Box>
          </Box>
        </Box>
      </Card>
      <Snackbar
        color="error"
        icon={<Warning />}
        title="Login Failed"
        content={nonFieldErrMsg}
        open={nonFieldErrMsg !== ''}
        onClose={() => setNonFieldErrMsg('')}
        close={() => setNonFieldErrMsg('')}
        bgWhite
      />
    </BasicLayout>
  );
}

export default Login;
