import React from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import Box from 'components/Box';
import Typography from 'components/Typography';
import Avatar from 'components/Avatar';
import Button from 'components/Button';
import { useContext } from 'react';
import StudentsContext from 'context/StudentsContext';

function ProfilesList({ profiles, onClickView }) {
  const studentsContext = useContext(StudentsContext);

  const renderProfiles = profiles.map(
    ({ id, profile_image, first_name, last_name, class_section }) => (
      <Box
        key={id}
        component="li"
        display="flex"
        alignItems="center"
        py={1}
        mb={1}>
        <Box mr={2}>
          <Avatar src={profile_image} alt="something here" shadow="md" />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center">
          <Typography variant="button" fontWeight="medium">
            {`${first_name} ${last_name}`}
          </Typography>
          <Typography variant="caption" color="text">
            {class_section?.school_class.name}, {class_section?.name}
          </Typography>
        </Box>
        <Box ml="auto">
          <Button
            onClick={() => {
              studentsContext.getStudent(id);
              onClickView();
            }}
            variant="text"
            color="info">
            view
          </Button>
        </Box>
      </Box>
    )
  );

  return (
    <Card sx={{ boxShadow: true }}>
      <Box pt={2} px={2}>
        <Typography variant="h6" fontWeight="medium">
          Children
        </Typography>
      </Box>
      <Box p={2}>
        <Box component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {renderProfiles}
        </Box>
      </Box>
    </Card>
  );
}

ProfilesList.defaultProps = {
  shadow: true
};

ProfilesList.propTypes = {
  profiles: PropTypes.arrayOf(PropTypes.object).isRequired,
  shadow: PropTypes.bool
};

export default ProfilesList;
