import { NOTICES } from 'constants/endpoints';
import React, { createContext, useState, useEffect, useContext } from 'react';
import AuthContext from './AuthContext';
import { ADD_COMMENT } from 'constants/endpoints';
import { DELETE_COMMENT } from 'constants/endpoints';

const NoticesContext = createContext();

export const NoticesProvider = ({ children }) => {
  const [notices, setNotices] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const authContext = useContext(AuthContext);

  const addComment = async (noticeId, comment) => {
    setSubmitting(true);
    const response = await fetch(ADD_COMMENT.replace('noticeId', noticeId), {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ user_id: authContext.user.id, comment })
    });
    const data = await response.json();
    if (data?.code === 201) {
      const notice = notices.find((n) => n.id === noticeId);
      notice.comments.push(data.data.comment);
      const values = notices;
      values.splice(
        values.map((value) => value.id).indexOf(noticeId),
        1,
        notice
      );
      setNotices(values);
    }
    setSubmitting(false);
    return true;
  };

  const deleteComment = async (noticeId, commentId) => {
    setSubmitting(true);
    const response = await fetch(
      DELETE_COMMENT.replace('commentId', commentId),
      {
        method: 'DELETE'
      }
    );
    const data = await response.json();
    if (data.code === 200) {
      const notice = notices.find((n) => n.id === noticeId);
      notice.comments.splice(
        notice.comments.map((comment) => comment.id).indexOf(commentId),
        1
      );
      const values = notices;
      values.splice(
        values.map((value) => value.id).indexOf(notice.id),
        1,
        notice
      );
      setNotices(values);
    }
    setSubmitting(false);
  };

  useEffect(() => {
    if (authContext.isLoggedIn) {
      fetch(NOTICES).then(async (response) => {
        const data = await response.json();
        if (data?.code === 200) {
          setNotices(data.data.filter((notice) => notice.post));
        }
      });
    }
  }, [authContext]);
  return (
    <NoticesContext.Provider
      value={{ notices, addComment, submitting, deleteComment }}>
      {children}
    </NoticesContext.Provider>
  );
};

export default NoticesContext;
