export const STATUS = {
  UNPAID: 1,
  PARTIALLY_PAID: 2,
  PAID: 3,
  CANCELLED: 4
};

export const USER_STATUS = {
  ACTIVE: '1',
  INACTIVE: '2',
  BLOCKED: '3'
};

export const EXAM_TYPES = {
  QUIZ: '1',
  TEST: '2',
  EXAM: '3'
};

export const EXAM_STATUS = {
  PENDING: '1',
  'IN PROGRESS': '2',
  COMPLETED: '3',
  CANCELLED: '4'
};
