import React from 'react';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Box from 'components/Box';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import Navbar from 'components/Navbar';
import ProfileInfoCard from 'components/ProfileInfoCard';
import Header from 'pages/profile/components/Header';
import PlatformSettings from 'pages/profile/components/PlatformSettings';
import moment from 'moment';
import { useContext } from 'react';
import AuthContext from 'context/AuthContext';

function Profile() {
  const authContext = useContext(AuthContext);
  return (
    <DashboardLayout>
      <Navbar />
      <Box mb={2} />
      <Header
        firstName={authContext.user?.user_profile.first_name}
        lastName={authContext.user?.user_profile.last_name}
        prfileImage={authContext.user?.user_profile.profile_image}>
        <Box mt={5} mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <PlatformSettings />
            </Grid>
            <Grid item xs={12} md={6} sx={{ display: 'flex' }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <ProfileInfoCard
                title="profile information"
                info={{
                  email: authContext.user?.email,
                  mobile: authContext.user?.user_profile.phone_number,
                  birthDate: moment(authContext.user?.user_profile.dob).format(
                    'LL'
                  ),
                  nationality: authContext.user?.user_profile.nationality,
                  gender: authContext.user?.user_profile.gender,
                  'ID Type': authContext.user?.user_profile.id_type,
                  'ID Number': authContext.user?.user_profile.id_number,
                  address: authContext.user?.user_profile.address
                }}
                shadow={false}
              />
            </Grid>
          </Grid>
        </Box>
      </Header>
    </DashboardLayout>
  );
}

export default Profile;
