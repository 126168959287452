import React from 'react';
import Box from 'components/Box';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import Navbar from 'components/Navbar';
import Notice from './components/Notice';
import {
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  Grid
} from '@mui/material';
import ProfilesList from './components/ProfilesList';
import { useContext } from 'react';
import StudentsContext from 'context/StudentsContext';
import { useState } from 'react';
import SlideUpTransition from 'components/SlideUpTransition';
import Typography from 'components/Typography';
import Button from 'components/Button';
import moment from 'moment';
import Avatar from 'components/Avatar';
import NoticesContext from 'context/NoticesContext';

function Dashboard() {
  const studentsContext = useContext(StudentsContext);
  const noticesContext = useContext(NoticesContext);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <DashboardLayout>
      <Navbar />
      <Dialog
        open={isOpen}
        maxWidth="md"
        fullWidth
        onClose={() => setIsOpen(false)}
        TransitionComponent={SlideUpTransition}
        aria-labelledby="form-dialog-title">
        <DialogContent>
          <Card
            sx={{
              position: 'relative',
              mx: 3,
              py: 2,
              px: 2
            }}>
            <Grid container spacing={3} alignItems="center">
              <Grid item>
                <Avatar
                  src={studentsContext.selectedStudent?.profile_image}
                  alt="profile-image"
                  size="xl"
                  shadow="sm"
                />
              </Grid>
              <Grid item>
                <Box height="100%" mt={0.5} lineHeight={1}>
                  <Typography variant="h5" fontWeight="medium">
                    {`${studentsContext.selectedStudent?.first_name} ${studentsContext.selectedStudent?.last_name}`}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} md={6}>
                <Box display="flex" py={1} pr={2}>
                  <Typography
                    variant="button"
                    fontWeight="bold"
                    textTransform="capitalize">
                    Birth Date: &nbsp;
                  </Typography>
                  <Typography
                    variant="button"
                    fontWeight="regular"
                    color="text">
                    &nbsp;
                    {moment(studentsContext.selectedStudent?.dob).format('LL')}
                  </Typography>
                </Box>
                <Box display="flex" py={1} pr={2}>
                  <Typography
                    variant="button"
                    fontWeight="bold"
                    textTransform="capitalize">
                    Gender: &nbsp;
                  </Typography>
                  <Typography
                    variant="button"
                    fontWeight="regular"
                    color="text">
                    &nbsp;
                    {studentsContext.selectedStudent?.gender}
                  </Typography>
                </Box>
                <Box display="flex" py={1} pr={2}>
                  <Typography
                    variant="button"
                    fontWeight="bold"
                    textTransform="capitalize">
                    Phone Number: &nbsp;
                  </Typography>
                  <Typography
                    variant="button"
                    fontWeight="regular"
                    color="text">
                    &nbsp;
                    {studentsContext.selectedStudent?.phone_number}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box display="flex" py={1} pr={2}>
                  <Typography
                    variant="button"
                    fontWeight="bold"
                    textTransform="capitalize">
                    Address: &nbsp;
                  </Typography>
                  <Typography
                    variant="button"
                    fontWeight="regular"
                    color="text">
                    &nbsp;
                    {studentsContext.selectedStudent?.address}
                  </Typography>
                </Box>
                <Box display="flex" py={1} pr={2}>
                  <Typography
                    variant="button"
                    fontWeight="bold"
                    textTransform="capitalize">
                    Nationality: &nbsp;
                  </Typography>
                  <Typography
                    variant="button"
                    fontWeight="regular"
                    color="text">
                    &nbsp;
                    {studentsContext.selectedStudent?.nationality}
                  </Typography>
                </Box>
                <Box display="flex" py={1} pr={2}>
                  <Typography
                    variant="button"
                    fontWeight="bold"
                    textTransform="capitalize">
                    Code: &nbsp;
                  </Typography>
                  <Typography
                    variant="button"
                    fontWeight="regular"
                    color="text">
                    &nbsp;
                    {studentsContext.selectedStudent?.code}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)}>Okay</Button>
        </DialogActions>
      </Dialog>
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <ProfilesList
              profiles={studentsContext.students}
              onClickView={() => setIsOpen(true)}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            {noticesContext.notices.length === 0 && (
              <Typography color="text">
                There are currently no notices from the school
              </Typography>
            )}
            {noticesContext.notices?.map((notice) => {
              return (
                <div key={notice.id}>
                  <Notice
                    value={notice}
                    submitting={noticesContext.submitting}
                    handleAddComment={async (comment) => {
                      return await noticesContext.addComment(
                        notice.id,
                        comment
                      );
                    }}
                    handleDelete={(commentId) =>
                      noticesContext.deleteComment(notice.id, commentId)
                    }
                  />
                  <br />
                </div>
              );
            })}
          </Grid>
        </Grid>
      </Box>
    </DashboardLayout>
  );
}

export default Dashboard;
