import { STUDENTS } from 'constants/endpoints';
import React, { createContext } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import AuthContext from './AuthContext';
import { useState } from 'react';

const StudentsContext = createContext();

export const StudentsProvider = ({ children }) => {
  const authContext = useContext(AuthContext);
  const [students, setStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);

  const getStudent = (id) => {
    const student = students.find((student) => student.id === id);
    setSelectedStudent(student);
  };

  useEffect(() => {
    if (authContext.isLoggedIn) {
      fetch(STUDENTS.replace('parentId', authContext.user?.id), {
        method: 'GET'
      }).then(async (response) => {
        const body = await response.json();
        if (body?.code === 200) {
          setStudents(body.data.students);
        }
      });
    }
  }, [authContext]);
  return (
    <StudentsContext.Provider value={{ students, selectedStudent, getStudent }}>
      {children}
    </StudentsContext.Provider>
  );
};

export default StudentsContext;
