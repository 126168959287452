import React from 'react';
import { Lock } from '@mui/icons-material';
import { Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import Typography from 'components/Typography';
import Button from 'components/Button';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh'
  },
  icon: {
    fontSize: '64px',
    color: theme.palette.primary.main
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    color: theme.palette.primary.main,
    textAlign: 'center'
  },
  text: {
    marginBottom: theme.spacing(2),
    textAlign: 'center'
  },
  button: {
    marginTop: theme.spacing(2)
  }
}));

const UnauthorizedPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Container className={classes.container} maxWidth="sm">
      <Lock className={classes.icon} />
      <Typography variant="h4" component="h1" className={classes.title}>
        Oops! Access Denied
      </Typography>
      <Typography variant="body1" className={classes.text}>
        It seems like you've stumbled upon a top-secret page.
        <br />
        Unfortunately, your security clearance level doesn't allow access.
        <br />
        Please contact the school administrator for further assistance.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={() => navigate('/')}>
        Go Back Home
      </Button>
    </Container>
  );
};

export default UnauthorizedPage;
