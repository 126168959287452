import PropTypes from 'prop-types';
import React from 'react';
import Box from 'components/Box';
import Typography from 'components/Typography';
import { useTimeline } from 'context/TimelineContext';
import timelineItem from 'components/TimelineItem/styles';
import moment from 'moment';
import { Delete } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';

function TimelineItem({
  color,
  icon,
  title,
  dateTime,
  description,
  lastItem,
  handleDelete,
  canDelete
}) {
  const isDark = useTimeline();

  return (
    <Box
      position="relative"
      mb={3}
      sx={(theme) => timelineItem(theme, { lastItem, isDark })}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgColor={color}
        color="white"
        width="2rem"
        height="2rem"
        borderRadius="50%"
        position="absolute"
        top="8%"
        left="2px"
        zIndex={2}
        sx={{ fontSize: ({ typography: { size } }) => size.sm }}>
        {icon}
      </Box>
      <Box
        ml={5.75}
        pt={description ? 0.7 : 0.5}
        lineHeight={0}
        maxWidth="30rem">
        <Typography
          variant="button"
          fontWeight="medium"
          color={isDark ? 'white' : 'dark'}>
          {title} &nbsp;
          <Typography variant="caption" color="text">
            {moment(dateTime).fromNow()}
          </Typography>
        </Typography>
        <Box mt={2} mb={1.5}>
          <Typography variant="button" color={isDark ? 'white' : 'dark'}>
            {description}
          </Typography>
          {canDelete && (
            <Tooltip title="Delete">
              <IconButton>
                <Delete
                  color="error"
                  style={{
                    paddingTop: 7,
                    marginBottom: 6,
                    cursor: 'pointer'
                  }}
                  onClick={handleDelete}
                />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>
    </Box>
  );
}

TimelineItem.defaultProps = {
  color: 'info',
  lastItem: false,
  description: ''
};

TimelineItem.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'dark',
    'light'
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  dateTime: PropTypes.string.isRequired,
  description: PropTypes.string,
  lastItem: PropTypes.bool
};

export default TimelineItem;
