import React, { useContext, useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from 'components/Box';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import Navbar from 'components/Navbar';
import ReportsLineChart from 'components/ReportsLineChart';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';
import SlideUpTransition from 'components/SlideUpTransition';
import Button from 'components/Button';
import Typography from 'components/Typography';
import logo from 'assets/images/logo.png';
import './styles.css';
import jsPDF from 'jspdf';
import StudentResultsContext from 'context/StudentResultsContext';
import StudentsContext from 'context/StudentsContext';

function Results() {
  const studentResultsContext = useContext(StudentResultsContext);
  const studentsContext = useContext(StudentsContext);
  const [isOpen, setIsOpen] = useState(false);
  const renderDetailedTemplate = () => {
    const results = studentResultsContext.studentResults.results;
    const student = studentsContext.students.find(
      (s) => s.id === studentResultsContext.studentResults.id
    );
    return (
      <div id="pdf" className="pdf">
        <div className="logo">
          <img src={logo} alt="School Logo" width={100} />
        </div>

        <table>
          <thead>
            <tr>
              <th>Subject</th>
              <th>Score</th>
              <th>Grade</th>
              <th>Class Average</th>
              <th>Grade Average</th>
            </tr>
          </thead>
          <tbody>
            {results
              ?.filter(
                (result) =>
                  result.exam.school_session.name ===
                    studentResultsContext.filter.session &&
                  result.exam.school_semester.name ===
                    studentResultsContext.filter.semester
              )
              ?.map((result, index) => (
                <tr key={index}>
                  <td>{result.exam.course.name}</td>
                  <td>{result.score}</td>
                  <td>{result.grade}</td>
                  <td>{result.class_average}</td>
                  <td>{result.grade_average}</td>
                </tr>
              ))}
            <tr>
              <td colSpan={7} className="custom-td">
                {studentResultsContext.studentResults.names}
              </td>
            </tr>
            <tr>
              <td colSpan={7} className="custom-td">
                {student?.class_section.school_class.name},{' '}
                {student?.class_section.name}
              </td>
            </tr>
            <tr>
              <td colSpan={7} className="custom-td">
                Results for:{' '}
                {results?.length > 0
                  ? `${results[0].exam.school_session.name}, ${results[0].exam.school_semester.name}`
                  : 'Unknown'}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <DashboardLayout>
      <Navbar />
      <Dialog
        open={isOpen}
        maxWidth="lg"
        fullWidth
        onClose={() => setIsOpen(false)}
        TransitionComponent={SlideUpTransition}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          <Typography color="dark">
            {studentResultsContext.studentResults?.names} (2023 Term 1)
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box component="li" display="flex" alignItems="center" py={1} mb={1}>
            <FormControl fullWidth>
              <InputLabel id="year">Select year</InputLabel>
              <Select
                labelId="year"
                id="year-select"
                label="Select year"
                style={{ height: '40px', marginRight: '30px' }}
                defaultValue={studentResultsContext.filter.session}>
                {studentResultsContext.filter.sessions
                  ?.filter(
                    (session, index, self) => self.indexOf(session) === index
                  )
                  ?.map((session) => (
                    <MenuItem key={session} value={session}>
                      {session}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="term">Select term</InputLabel>
              <Select
                labelId="term"
                id="term-select"
                label="Select term"
                style={{ height: '40px' }}
                defaultValue={studentResultsContext.filter.semester}>
                {studentResultsContext.filter.semesters
                  ?.filter(
                    (session, index, self) => self.indexOf(session) === index
                  )
                  ?.map((session) => (
                    <MenuItem key={session} value={session}>
                      {session}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <Box ml={2}>
              <Button color="error" size="small">
                Reset filters
              </Button>
            </Box>
            <Box ml={2}>
              <Button
                color="info"
                size="small"
                onClick={() => {
                  const doc = new jsPDF();
                  doc
                    .html(document.getElementById('pdf'), {
                      x: 15,
                      y: 15,
                      width: 170,
                      windowWidth: 650
                    })
                    .then(() => {
                      doc.save(
                        `${studentResultsContext.studentResults?.names}`
                      );
                    });
                }}>
                Download Results
              </Button>
            </Box>
          </Box>
          {renderDetailedTemplate()}
        </DialogContent>
        <DialogActions>
          <Button color="info" onClick={() => setIsOpen(false)}>
            Okay
          </Button>
        </DialogActions>
      </Dialog>
      <Box py={3}>
        <Box mt={4.5}>
          <Grid container spacing={3}>
            {Object.keys(studentResultsContext.studentAnalytics).map(
              (student, index) => {
                return (
                  <Grid key={student} item xs={12} md={6}>
                    <Box mb={3}>
                      <ReportsLineChart
                        color={index % 2 === 0 ? 'info' : 'success'}
                        title={student}
                        chart={{
                          labels: Object.keys(
                            studentResultsContext.studentAnalytics[student]
                          ),
                          datasets: {
                            data: Object.values(
                              studentResultsContext.studentAnalytics[student]
                            )
                          }
                        }}
                        image={student.profile_image}
                        handleView={() => {
                          studentResultsContext.getStudentResults(student);
                          setIsOpen(true);
                        }}
                      />
                    </Box>
                  </Grid>
                );
              }
            )}
          </Grid>
        </Box>
      </Box>
    </DashboardLayout>
  );
}

export default Results;
