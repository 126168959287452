import React from 'react';
import Card from '@mui/material/Card';
import Box from 'components/Box';
import Typography from 'components/Typography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import Navbar from 'components/Navbar';
import DataTable from 'components/DataTable';
import PaymentsContext from 'context/PaymentsContext';
import { useContext } from 'react';
import { formatCurrency } from 'utils/helpers';

function Payments() {
  const paymentsContext = useContext(PaymentsContext);
  const columns = [
    { Header: 'date', accessor: 'date', align: 'center' },
    { Header: 'description', accessor: 'description', align: 'center' },
    {
      Header: 'payment reference',
      accessor: 'invoiceNumber',
      align: 'left'
    },
    { Header: 'amount', accessor: 'invoiceAmount', align: 'left' }
  ];
  return (
    <DashboardLayout>
      <Navbar />
      <Box pt={6} pb={3}>
        <Card>
          <Box
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info">
            <Typography variant="h6" color="white">
              Payments
            </Typography>
          </Box>
          <Box pt={3}>
            <DataTable
              table={{
                columns,
                rows: paymentsContext.payments?.map((payment) => {
                  return {
                    invoiceNumber: (
                      <Typography
                        variant="caption"
                        color="text"
                        fontWeight="bold">
                        {payment.payment_reference}
                      </Typography>
                    ),
                    invoiceAmount: (
                      <Typography
                        variant="caption"
                        color="text"
                        fontWeight="bold">
                        {formatCurrency(payment.amount_paid)}
                      </Typography>
                    ),
                    date: (
                      <Typography
                        variant="caption"
                        color="text"
                        fontWeight="medium">
                        {payment.payment_date}
                      </Typography>
                    ),
                    description: (
                      <Typography
                        variant="caption"
                        color="text"
                        fontWeight="medium">
                        {payment.payment_notes}
                      </Typography>
                    )
                  };
                })
              }}
              isSorted={false}
              entriesPerPage={false}
              showTotalEntries={false}
              noEndBorder
            />
          </Box>
        </Card>
      </Box>
    </DashboardLayout>
  );
}

export default Payments;
