import React from 'react';
import Card from '@mui/material/Card';
import Box from 'components/Box';
import Typography from 'components/Typography';
import { Container, Grid, Paper, TextField } from '@mui/material';
import Alert from 'components/Alert';
import { useState } from 'react';
import Button from 'components/Button';
import { useContext } from 'react';
import AuthContext from 'context/AuthContext';
import Snackbar from 'components/Snackbar';
import { CheckCircle } from '@mui/icons-material';
import Loader from 'components/Loader';

function PlatformSettings() {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const authContext = useContext(AuthContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!oldPassword || !newPassword || !confirmPassword) {
      setError('Please fill in all fields.');
      return;
    }
    if (newPassword !== confirmPassword) {
      setError('New password and confirm password must match.');
      return;
    }
    authContext.changePassword(oldPassword, newPassword).then(() => {
      setSuccess(true);
      setOldPassword('');
      setNewPassword('');
      setConfirmPassword('');
    });
  };

  return (
    <Card sx={{ boxShadow: 'none' }}>
      <Box p={2}>
        <Typography variant="h6" fontWeight="medium" textTransform="capitalize">
          platform settings
        </Typography>
      </Box>
      <Box pt={1} pb={2} px={2} lineHeight={1.25}>
        <Container maxWidth="sm">
          <Paper elevation={3} sx={{ padding: 3, marginTop: 5 }}>
            <Typography variant="h6" component="h2" gutterBottom>
              Password Reset
            </Typography>
            {error && <Alert severity="error">{error}</Alert>}
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    required
                    label="Old Password"
                    type="password"
                    fullWidth
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    label="New Password"
                    type="password"
                    fullWidth
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    label="Confirm New Password"
                    type="password"
                    fullWidth
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="info"
                    disabled={authContext.loading}
                    fullWidth>
                    {authContext.loading ? <Loader /> : 'Reset Password'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Container>
      </Box>
      <Snackbar
        color="success"
        icon={<CheckCircle />}
        title="Success"
        content="Your password was reset successfully"
        open={success}
        onClose={() => setSuccess(false)}
        close={() => setSuccess(false)}
        bgWhite
      />
    </Card>
  );
}

export default PlatformSettings;
