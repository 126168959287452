import React from 'react';
import Card from '@mui/material/Card';
import Box from 'components/Box';
import Typography from 'components/Typography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import Navbar from 'components/Navbar';
import DataTable from 'components/DataTable';
import PaymentsContext from 'context/PaymentsContext';
import { STATUS } from 'constants';
import Badge from 'components/Badge';
import { useContext } from 'react';
import { formatCurrency } from 'utils/helpers';
import { INVOICE_DETAILS } from 'constants/routes';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Loader from 'components/Loader';

function Invoices() {
  const paymentsContext = useContext(PaymentsContext);
  const columns = [
    { Header: 'date', accessor: 'date', align: 'center' },
    {
      Header: 'invoice number',
      accessor: 'invoiceNumber',
      align: 'left'
    },
    { Header: 'due date', accessor: 'dueDate', align: 'center' },
    { Header: 'amount', accessor: 'invoiceAmount', align: 'left' },
    { Header: 'balance', accessor: 'balance', align: 'left' },
    { Header: 'status', accessor: 'status', align: 'center' },
    { Header: 'action', accessor: 'action', align: 'center' }
  ];
  return (
    <DashboardLayout>
      <Navbar />
      <Box pt={6} pb={3}>
        {paymentsContext.loading ? (
          <Loader />
        ) : (
          <Card>
            <Box
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info">
              <Typography variant="h6" color="white">
                Invoices
              </Typography>
            </Box>
            <Box pt={3}>
              <DataTable
                table={{
                  columns,
                  rows: paymentsContext.invoices?.map((invoice) => {
                    const colors = ['error', 'warning', 'success', 'info'];
                    return {
                      invoiceNumber: (
                        <Typography
                          variant="caption"
                          color="text"
                          fontWeight="bold">
                          {invoice.invoice_number}
                        </Typography>
                      ),
                      invoiceAmount: (
                        <Typography
                          variant="caption"
                          color="text"
                          fontWeight="bold">
                          {formatCurrency(invoice.invoice_amount)}
                        </Typography>
                      ),
                      balance: (
                        <Typography
                          variant="caption"
                          color="text"
                          fontWeight="bold">
                          {formatCurrency(
                            invoice.invoice_amount -
                              invoice.payments.reduce(
                                (acc, payment) => acc + payment.amount_paid,
                                0
                              )
                          )}
                        </Typography>
                      ),
                      status: (
                        <Box ml={-1}>
                          <Badge
                            badgeContent={
                              Object.keys(STATUS)[invoice.status - 1]
                            }
                            color={colors[invoice.status - 1]}
                            variant="gradient"
                            size="sm"
                          />
                        </Box>
                      ),
                      date: (
                        <Typography
                          variant="caption"
                          color="text"
                          fontWeight="medium">
                          {moment(invoice.invoice_date).format('LL')}
                        </Typography>
                      ),
                      dueDate: (
                        <Typography
                          variant="caption"
                          color="text"
                          fontWeight="medium">
                          {moment(invoice.due_date).format('LL')}
                        </Typography>
                      ),
                      action: (
                        <Typography
                          component={Link}
                          to={`${INVOICE_DETAILS}?id=${invoice.id}`}
                          variant="caption"
                          color="info"
                          fontWeight="medium">
                          VIEW
                        </Typography>
                      )
                    };
                  })
                }}
                isSorted={false}
                entriesPerPage={{
                  defaultValue: 10,
                  entries: [10, 15, 20, 25, 50]
                }}
                showTotalEntries
              />
            </Box>
          </Card>
        )}
      </Box>
    </DashboardLayout>
  );
}

export default Invoices;
