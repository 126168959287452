import React from 'react';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import Typography from 'components/Typography';
import Button from 'components/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh'
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    textAlign: 'center'
  },
  subtitle: {
    marginBottom: theme.spacing(2),
    textAlign: 'center'
  },
  button: {
    marginTop: theme.spacing(2)
  }
}));

const NotFoundPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <div className={classes.root}>
      <Typography variant="h2" className={classes.title}>
        404 - Page Not Found
      </Typography>
      <Typography variant="h5" className={classes.subtitle}>
        Oops! Looks like you took a wrong turn.
        <br />
        Don't worry, even the best explorers get lost sometimes!
      </Typography>
      <Button
        variant="contained"
        color="secondary"
        className={classes.button}
        onClick={() => navigate(-2)}>
        Go Back
      </Button>
    </div>
  );
};

export default NotFoundPage;
