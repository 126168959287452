import React, { createContext, useEffect, useState, useContext } from 'react';
import AuthContext from './AuthContext';
import { STUDENTS_RESULTS } from 'constants/endpoints';

const StudentResultsContext = createContext();

export const StudentResultsProvider = ({ children }) => {
  const [allStudents, setAllStudents] = useState([]);
  const [studentResults, setStudentResults] = useState([]);
  const [studentAnalytics, setStudentAnalytics] = useState([]);
  const [filter, setFilter] = useState({});
  const authContext = useContext(AuthContext);

  const changeFilter = (session, semester) => {
    setFilter({ ...filter, session, semester });
  };

  const getStudentResults = (name) => {
    const results = allStudents.find((student) => student.names === name);
    const sessions = results.results.map(
      (result) => result.exam.school_session.name
    );
    const semesters = results.results.map(
      (result) => result.exam.school_semester.name
    );
    setFilter({
      session: sessions[0],
      sessions,
      semester: semesters[0],
      semesters
    });
    setStudentResults(results);
  };

  useEffect(() => {
    const getStudentsResults = async () => {
      const response = await fetch(
        STUDENTS_RESULTS.replace('parentId', authContext.user.id),
        {
          method: 'GET'
        }
      );
      const data = await response.json();
      if (data.code === 200) {
        const resultsByStudent = {};
        for (const student of data.data.students) {
          const studentName = student.names;

          for (const result of student.results) {
            const academicYear = result.exam.school_session.name;
            const semester = result.exam.school_semester.name;
            const score = result.score;

            if (result.exam.exam_type !== '3') {
              continue;
            }
            if (!resultsByStudent[studentName]) {
              resultsByStudent[studentName] = {};
            }
            const academicYearSemester = `${academicYear} ${semester}`;
            if (!resultsByStudent[studentName][academicYearSemester]) {
              resultsByStudent[studentName][academicYearSemester] = score;
            } else {
              resultsByStudent[studentName][academicYearSemester] += score;
            }
          }
        }
        setAllStudents(data.data.students);
        setStudentAnalytics(resultsByStudent);
      }
    };
    if (authContext.user?.id) getStudentsResults();
  }, [authContext]);

  return (
    <StudentResultsContext.Provider
      value={{
        studentAnalytics,
        studentResults,
        getStudentResults,
        filter,
        changeFilter
      }}>
      {children}
    </StudentResultsContext.Provider>
  );
};

export default StudentResultsContext;
