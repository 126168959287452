import React from 'react';
import ReactDOM from 'react-dom';
import App from 'App';
import { BrowserRouter } from 'react-router-dom';
import { MaterialUIControllerProvider } from 'context';
import { AuthProvider } from 'context/AuthContext';
import { StudentsProvider } from 'context/StudentsContext';
import { PaymentsProvider } from 'context/PaymentsContext';
import { NoticesProvider } from 'context/NoticesContext';
import { StudentResultsProvider } from 'context/StudentResultsContext';

ReactDOM.render(
  <BrowserRouter>
    <AuthProvider>
      <NoticesProvider>
        <StudentsProvider>
          <StudentResultsProvider>
            <PaymentsProvider>
              <MaterialUIControllerProvider>
                <App />
              </MaterialUIControllerProvider>
            </PaymentsProvider>
          </StudentResultsProvider>
        </StudentsProvider>
      </NoticesProvider>
    </AuthProvider>
  </BrowserRouter>,
  document.getElementById('root')
);
