import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Box from 'components/Box';
import Typography from 'components/Typography';
import configs from 'components/ReportsLineChart/configs';
import Avatar from 'components/Avatar';
import Button from 'components/Button';

function ReportsLineChart({
  color,
  title,
  description,
  chart,
  image,
  handleView
}) {
  const { data, options } = configs(chart.labels || [], chart.datasets || {});

  return (
    <Card sx={{ height: '100%' }}>
      <Box padding="1rem">
        {useMemo(
          () => (
            <Box
              variant="gradient"
              bgColor={color}
              borderRadius="lg"
              coloredShadow={color}
              py={2}
              pr={0.5}
              mt={-5}
              height="18rem">
              <Line data={data} options={options} />
            </Box>
          ),
          [chart, color]
        )}
        <Box pt={3} pb={1} px={1}>
          <Box
            key={title}
            component="li"
            display="flex"
            alignItems="center"
            py={1}
            mb={1}>
            <Box mr={2}>
              <Avatar src={image} alt="something here" shadow="md" />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="center">
              <Typography variant="h6" textTransform="capitalize">
                {title}
              </Typography>
              <Typography
                component="div"
                variant="button"
                color="text"
                fontWeight="light">
                {description}
              </Typography>
            </Box>
          </Box>
          <Divider />
          <Button variant="text" color="info" onClick={handleView}>
            View detailed transcript
          </Button>
        </Box>
      </Box>
    </Card>
  );
}

ReportsLineChart.defaultProps = {
  color: 'dark',
  description: ''
};

ReportsLineChart.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'dark'
  ]),
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  chart: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.object])
  ).isRequired
};

export default ReportsLineChart;
