import React, { useEffect, useState } from 'react';
import 'firebase/auth';
import { LOGIN } from 'constants/endpoints';
import { USER_STATUS } from 'constants';
import { useNavigate } from 'react-router-dom';
import { ERROR_401 } from 'constants/routes';
import { RESET_PASSWORD } from 'constants/endpoints';

const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(JSON.parse(sessionStorage.getItem('user')));
  const [isLoggedIn, setIsLoggedIn] = useState(
    sessionStorage.getItem('isLoggedIn')
  );
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const login = async (email, password) => {
    setLoading(true);
    const res = await fetch(LOGIN, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, password })
    });
    const body = await res.json();
    if (body.code === 200) {
      const statuses = Object.values(USER_STATUS);
      if (body.data.user.status !== statuses[0]) {
        setLoading(false);
        navigate(ERROR_401);
        return;
      }
      setUser(body.data.user);
      setIsLoggedIn(true);
      sessionStorage.setItem('user', JSON.stringify(body.data.user));
      sessionStorage.setItem('isLoggedIn', true);
    } else {
      setIsLoggedIn(false);
      setUser(null);
      sessionStorage.removeItem('user');
      sessionStorage.removeItem('isLoggedIn');
    }
    setLoading(false);
    return body;
  };

  const logout = () => {
    setIsLoggedIn(false);
    setUser(null);
    sessionStorage.removeItem('isLoggedIn');
    sessionStorage.removeItem('user');
  };

  const changePassword = async (old_password, new_password) => {
    setLoading(true);
    await fetch(RESET_PASSWORD.replace('userId', user.id), {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ old_password, new_password })
    });
    setLoading(false);
  };

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem('user'));
    const isLoggedIn = sessionStorage.getItem('isLoggedIn');
    if (user && isLoggedIn) {
      setIsLoggedIn(true);
      setUser(user);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{ user, isLoggedIn, loading, login, logout, changePassword }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
