export const LOGIN = `${process.env.REACT_APP_BASE_URL}/v1/auth/login`;
export const STUDENTS = `${process.env.REACT_APP_BASE_URL}/v1/parents/parentId/students`;
export const INVOICES = `${process.env.REACT_APP_BASE_URL}/v1/parents/parentId/invoices`;
export const PAYMENTS = `${process.env.REACT_APP_BASE_URL}/v1/parents/parentId/payments`;
export const INVOICE = `${process.env.REACT_APP_BASE_URL}/v1/invoices/id`;
export const NOTICES = `${process.env.REACT_APP_BASE_URL}/v1/notices`;
export const ADD_COMMENT = `${process.env.REACT_APP_BASE_URL}/v1/notices/noticeId/comments`;
export const RESET_PASSWORD = `${process.env.REACT_APP_BASE_URL}/v1/auth/users/userId/change-password`;
export const DELETE_COMMENT = `${process.env.REACT_APP_BASE_URL}/v1/notices/comments/commentId`;
export const STUDENTS_RESULTS = `${process.env.REACT_APP_BASE_URL}/v1/parents/parentId/students/results`;
