import React from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Box from 'components/Box';
import Typography from 'components/Typography';
import Avatar from 'components/Avatar';
import backgroundImage from 'assets/images/bg-profile.jpeg';

function Header({ children, firstName, lastName, profileImage }) {
  return (
    <Box position="relative" mb={5}>
      <Box
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({
            functions: { rgba, linearGradient },
            palette: { gradients }
          }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.6),
              rgba(gradients.info.state, 0.6)
            )}, url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: '50%',
          overflow: 'hidden'
        }}
      />
      <Card
        sx={{
          position: 'relative',
          mt: -8,
          mx: 3,
          py: 2,
          px: 2
        }}>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <Avatar
              src={profileImage}
              alt="profile-image"
              size="xl"
              shadow="sm"
            />
          </Grid>
          <Grid item>
            <Box height="100%" mt={0.5} lineHeight={1}>
              <Typography variant="h5" fontWeight="medium">
                {`${firstName} ${lastName}`}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        {children}
      </Card>
    </Box>
  );
}

Header.defaultProps = {
  children: '',
  firstName: '',
  lastName: '',
  profileImage: ''
};

Header.propTypes = {
  children: PropTypes.node,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  profileImage: PropTypes.string
};

export default Header;
