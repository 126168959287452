import pxToRem from 'assets/theme/functions/pxToRem';

const step = {
  styleOverrides: {
    root: {
      padding: `0 ${pxToRem(6)}`
    }
  }
};

export default step;
