import React, { createContext, useContext, useEffect, useState } from 'react';
import AuthContext from './AuthContext';
import { PAYMENTS, INVOICE, INVOICES } from 'constants/endpoints';
import { useNavigate } from 'react-router-dom';
import { ERROR_401, ERROR_404 } from 'constants/routes';

const PaymentsContext = createContext();

export const PaymentsProvider = ({ children }) => {
  const authContext = useContext(AuthContext);
  const [invoices, setInvoices] = useState([]);
  const [payments, setPayments] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const getInvoice = async (invoiceId) => {
    setLoading(true);
    if (invoices.map((invoice) => invoice.id).includes(invoiceId)) {
      setSelectedInvoice(invoices.find((invoice) => invoice.id === invoiceId));
      setLoading(false);
      return;
    }
    const response = await fetch(INVOICE.replace('id', invoiceId), {
      method: 'GET'
    });
    const data = await response.json();
    if (data?.code === 200) {
      if (data.data.user_id !== authContext.user.id) {
        navigate(ERROR_401);
        return;
      }
      const invoice = data.data;
      invoice.paid = invoice.payments.reduce(
        (acc, payment) => acc + payment.amount_paid,
        0
      );
      invoice.balance = invoice.invoice_amount - invoice.paid;
      setSelectedInvoice(data.data);
    } else if (data?.code === 404) {
      navigate(ERROR_404);
    }
    setLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      let response = await fetch(
        INVOICES.replace('parentId', authContext.user?.id),
        {
          method: 'GET'
        }
      );
      const invoices = await response.json();
      if (invoices?.code === 200) {
        setInvoices(invoices.data.invoices);
      }
      response = await fetch(
        PAYMENTS.replace('parentId', authContext.user?.id),
        {
          method: 'GET'
        }
      );
      const payments = await response.json();
      if (payments?.code === 200) {
        setPayments(payments.data.payments);
      }
      setLoading(false);
    };
    if (authContext.isLoggedIn) fetchData();
  }, [authContext]);
  return (
    <PaymentsContext.Provider
      value={{ invoices, payments, selectedInvoice, loading, getInvoice }}>
      {children}
    </PaymentsContext.Provider>
  );
};

export default PaymentsContext;
