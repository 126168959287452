import React from 'react';
import Card from '@mui/material/Card';
import Box from 'components/Box';
import Typography from 'components/Typography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import Navbar from 'components/Navbar';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';
import { useContext } from 'react';
import PaymentsContext from 'context/PaymentsContext';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { formatCurrency } from 'utils/helpers';
import DataTable from 'components/DataTable';
import moment from 'moment';
import Loader from 'components/Loader';

function InvoiceDetails() {
  const paymentsContext = useContext(PaymentsContext);
  const params = useSearchParams();
  const columns = [
    { Header: 's/n', accessor: 'id', align: 'center' },
    { Header: 'description', accessor: 'description', align: 'center' },
    { Header: 'date', accessor: 'date', align: 'center' },
    {
      Header: 'payment reference',
      accessor: 'invoiceNumber',
      align: 'left'
    },
    { Header: 'amount', accessor: 'invoiceAmount', align: 'left' }
  ];
  useEffect(() => {
    const fetchData = async () => {
      await paymentsContext.getInvoice(params[0].get('id'));
    };
    fetchData();
  }, []);

  return (
    <DashboardLayout>
      <Navbar />
      <Box pt={6} pb={3}>
        {paymentsContext.loading ? (
          <Loader />
        ) : (
          <>
            <Card>
              <Box
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info">
                <Typography variant="h6" color="white">
                  Invoice: {paymentsContext.selectedInvoice?.invoice_number}
                </Typography>
              </Box>
              <Box pt={3}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>Description</TableCell>
                        <TableCell>Amount Due</TableCell>
                        <TableCell>Paid</TableCell>
                        <TableCell>Balance</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          {paymentsContext.selectedInvoice?.invoice_notes}:
                        </TableCell>
                        <TableCell>
                          {formatCurrency(
                            paymentsContext.selectedInvoice?.invoice_amount
                          )}
                        </TableCell>
                        <TableCell>
                          {formatCurrency(
                            paymentsContext.selectedInvoice?.paid
                          )}
                        </TableCell>
                        <TableCell>
                          {formatCurrency(
                            paymentsContext.selectedInvoice?.balance
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Card>
            <br />
            <br />
            <Card>
              <Box
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info">
                <Typography variant="h6" color="white">
                  Payments
                </Typography>
              </Box>
              <Box pt={3}>
                <DataTable
                  table={{
                    columns,
                    rows: paymentsContext.selectedInvoice
                      ? paymentsContext.selectedInvoice.payments.map(
                          (payment, index) => {
                            return {
                              id: (
                                <Typography
                                  variant="caption"
                                  color="text"
                                  fontWeight="bold">
                                  {index + 1}
                                </Typography>
                              ),
                              invoiceNumber: (
                                <Typography
                                  variant="caption"
                                  color="text"
                                  fontWeight="bold">
                                  {payment.payment_reference}
                                </Typography>
                              ),
                              invoiceAmount: (
                                <Typography
                                  variant="caption"
                                  color="text"
                                  fontWeight="bold">
                                  {formatCurrency(payment.amount_paid)}
                                </Typography>
                              ),
                              date: (
                                <Typography
                                  variant="caption"
                                  color="text"
                                  fontWeight="medium">
                                  {moment(payment.payment_date).format('LL')}
                                </Typography>
                              ),
                              description: (
                                <Typography
                                  variant="caption"
                                  color="text"
                                  fontWeight="medium">
                                  {payment.payment_notes}
                                </Typography>
                              )
                            };
                          }
                        )
                      : []
                  }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </Box>
            </Card>
          </>
        )}
      </Box>
    </DashboardLayout>
  );
}

export default InvoiceDetails;
